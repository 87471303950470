// Modules
import React from "react";
// Component
import Footer from "../components/Footer";
// Images
import trip from "../images/phone-trip.png";
import places from "../images/phone-savedplaces-chi.png";
import chat from "../images/phone-chats.png";
import expense from "../images/phone-expense.png";

// import trip from "../images/phone-chatchannels-copy.png";
// import places from "../images/phone-expenses-copy.png";
// import chat from "../images/phone-savedplaces-copy.png";
// import expense from "../images/phone-trip-copy.png";

const LandingPage = () => {
  return (
    <>
      <section className="hero is-large">
        <div className="hero-body">
          <div className="container">
            <h1 className="hero-title">Travel better together.</h1>
            <p className="subtitle">Plan your next trip from one place.</p>
          </div>
        </div>

        <a
          className="photo-credit"
          // href="https://pixabay.com/users/paulinawhite-988583/"
          href="https://unsplash.com/@miinyuii"
          target="_blank"
          rel="noopener noreferrer"
        >
          {/* Photo by Paulina White */}
          Photo by Duy Pham
        </a>
      </section>

      <section className="section flights-section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <h2 className="title navy">
                Say bye to email <span className="line-break" />
                {/* endless emails & spreadsheets. */}& text chains.
              </h2>
              <p className="body-copy">
                Plan, organize, and communicate all your{" "}
                <span className="line-break" />
                trip details from one place.
              </p>
            </div>
            <div className="column is-centered">
              <img className="section-img" src={trip} alt="placeholder" />
            </div>
          </div>
        </div>
      </section>

      {/* <img className="line-break" src={lineBreakOne} alt="line" /> */}

      <section className="section lodging-section">
        <div className="container">
          <div className="columns is-vcentered reverse-columns">
            <div className="column is-centered">
              <img className="section-img" src={places} alt="placeholder" />
            </div>
            <div className="column">
              <h2 className="title teal">
                Search and save <span className="line-break" />
                must see places.
              </h2>
              <p className="body-copy">
                Keep track of all the things you want to see{" "}
                <span className="line-break" />
                and do with one click.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section fun-section">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column">
              <h2 className="title navy">
                Collaborate and <span className="line-break" /> stay up to date.
              </h2>
              <p className="body-copy">
                Keep the conversation focused. Share ideas easily. Make
                decisions quicker.
              </p>
            </div>
            <div className="column is-centered">
              <img className="section-img" src={chat} alt="placeholder" />
            </div>
          </div>
        </div>
      </section>

      <section className="section lodging-section">
        <div className="container">
          <div className="columns is-vcentered reverse-columns">
            <div className="column is-centered">
              <img className="section-img" src={expense} alt="placeholder" />
            </div>
            <div className="column">
              <h2 className="title teal">
                Track and split <span className="line-break" />
                expenses.
              </h2>
              <p className="body-copy">
                Know who bought what and what everyone owes. Without the hassle
                and math.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LandingPage;
