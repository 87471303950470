// Modules
import React from "react";
// Components
// import EmailCollector from "./EmailCollector";
// Images
import apple from "../images/AppStoreBadge.png";
import google from "../images/google-play-badge.png";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="footer">
        <div className="content">
          <h2 className="column nordic navy no-margin-bottom">
            Travel better together.
          </h2>

          <div className="columns is-vcentered is-mobile">
            <div className="column">{/* <EmailCollector /> */}</div>
            <a href="/">
              <img
                className="column is-narrow"
                src={google}
                alt="Google Play Store icon"
                width="175"
              />
            </a>
            <a href="/">
              <img
                className="column is-narrow"
                src={apple}
                alt="Apple App Store icon"
              />
            </a>
          </div>
        </div>
      </footer>

      <div className="rectangle">
        <ul className="columns content">
          <li className="column is-5 copy-right">
            Copyright ©{year} TravelCrue. All rights reserved
          </li>
          <li className="column footer-item-link">
            <a
              href="https://www.iubenda.com/privacy-policy/62794338"
              title="Privacy Policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li className="column footer-item-link">
            <a
              href="https://www.iubenda.com/terms-and-conditions/62794338"
              title="Terms and Conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </li>

          <li className="column footer-item-link">
            <a
              href="https://www.instagram.com/travelcrue/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li className="column footer-item-link">
            <a
              href="https://www.facebook.com/travelcrue"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
