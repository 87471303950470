import React from "react";
// Screens
import LandingPage from "./screens/LandingPage.js";
// Style
import "normalize.css";
import "./App.scss";

function App() {
  return (
    <div id="appContainer">
      <LandingPage />
    </div>
  );
}

export default App;
